import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  background-color: ${grayscale[100]};
  padding: 40px 0;

  @media ${device.tablet}{
    height: 518px;
  }
  
  @media (min-width: 1024px){
    padding: 52px 0;
    height: fit-content;
  }

  @media ${device.desktopXXL} {
    padding: 84px 0;
  }

  .textsDiv{
    @media ${device.tablet}{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 12px !important;
    }

    @media (min-width: 1024px){
      max-width: 40%;
      padding: 0 24px 0 0;
    }

    @media ${device.desktopXXL}{
      min-width: 35%;
    }
    
    .title{
      font-family: Citrina, sans-serif;
      font-weight: 700;
      font-size: 28px;
      line-height: 33.6px;
      color: ${grayscale[500]};
      margin-bottom: 16px;
      
      @media ${device.tablet}{
        margin-bottom: 24px;
      }

      @media (min-width: 1024px){
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 24px;
        font-family: Citrina, sans-serif;
      }

      @media ${device.desktopXXL}{
        font-size: 48px;
        line-height: 52.8px;
      }
    }

    .text{
      font-family: Inter;
      font-weight: 400;
      font-size: 14px;
      line-height: 16.94px;

      @media ${device.tablet}{
        margin-bottom: 32px;
      }

      @media (min-width: 1024px){
       font-size: 18px;
       line-height: 21.78px;
      }
    }
  }

  .cardsDiv{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${device.tablet} {
      padding-left: 12px !important;
    }
    
    @media (min-width: 1024px) {
      height: fit-content;
      padding: 0 0 0 24px !important;
    }
  }
`

export const Card = styled.article`
  background-color: white;
  border-radius: 8px;
  border: 1px solid ${grayscale[200]};
  padding: 16px;
  margin: 24px 0;
  display: flex;
  flex-direction: row;

  @media ${device.tablet}{
    flex-direction: column;
    border-radius: 16px;
    margin: 0;
    padding: 24px;
  }

  @media (min-width: 1024px){
    margin: 12px 0;
  }

  .iconDiv{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;
    margin-right: 16px;
    
    @media ${device.tablet}{
      width: 100%;
      margin-bottom: 16px;
    }

    @media (min-width: 1024px){
      margin-bottom: 32px;
    }

    .icon{ 
      height: 32px;
      width: 32px;
      
      @media ${device.tablet}{
        margin-right: 14px;
        height: 24px;
        width: 24px;
      }

      @media ${device.desktopXL}{
        height: 32px;
        width: 32px;
      }
    }
  }

  .cardTitle{
      margin-bottom: 8px;
      font-family: Inter;
      font-weight: 600;
      font-size: 18px;
      line-height: 21.78px;
      color: ${grayscale[500]};

      @media ${device.tablet}{
        margin: 0;
      }

      @media (min-width: 1024px){
        font-size: 20px;
        line-height: 24px;
      }

      @media ${device.desktopXXL}{
        font-size: 24px;
        line-height: 28.8px;
      }
  }

  .cardTextDiv{
    display: flex;
    flex-direction: column;
    width: fit-content;

    .cardText{
      margin: 0;
      font-family: Inter;
      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      color: ${grayscale[400]};

      @media ${device.tablet} {
        font-size: 14px;
      }

      @media ${device.desktopXXL}{
        font-size: 16px;
        line-height: 19.36px;
      }
    }
  }
`

export const DivPrincipal = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet}{
    justify-content: space-between;
    flex-direction: row;
    height: 100%;
  }

  @media ${device.desktopXXL}{
  }
`
