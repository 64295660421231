import React, { ChangeEvent, useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { UseFormMethods, useForm } from 'react-hook-form'
import useDomReady from 'src/hooks/window/useDomReady'
import axios from 'axios'

import * as S from './styles'
import * as Types from './types'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as URLS from 'src/config/api/Urls'

import { Input } from '@interco/inter-ui/components/Input'
import { Modal } from 'src/components/Modal'
import { Button } from '@interco/inter-ui/components/Button'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import AcceptTerms from 'src/components/AcceptTerms'
import OrangeDSIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import { ModalProps } from '../../types'

const ModalComponent = ({ isModalOpen, setIsModalOpen }: ModalProps) => {
  const { register, handleSubmit, errors, setValue }: UseFormMethods<Types.FormTypes> = useForm<Types.FormTypes>()
  const [ accept, setAccept ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)
  const domReady = useDomReady()
  const [ sendDataLayerEvent ] = useDataLayer()

  const [ submitState, setSubmitState ] = useState<Types.SubmitStateProps>({
    sent: false,
    error: false,
  })

  function handleSubmitState (toUpdate: Types.SubmitStateProps) {
    setSubmitState({
      ...submitState,
      ...toUpdate,
    })
  }

  function resetForm () {
    setValue('cellphone', '')
    setValue('consultorName', '')
    setValue('CpfCnpj', '')
    setValue('email', '')
    setValue('name', '')
    setValue('privacyAccept', false)

    handleSubmitState({
      error: false,
      sent: false,
    })
  }

  function onSubmitForm (data: Types.FormTypes) {
    setLoading(true)
    const formData = {
      campanha: 'Portal do Consultor',
      nome: data.name,
      CpfCnpj: data.CpfCnpj?.replaceAll(/\D/g, ''),
      email: data.email,
      aceite: true,
      conteudo01: `55${data.cellphone?.replace(/\D|\s/g, '')}`,
      conteudo02: data.consultorName,
    }

    try {
      axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      handleSubmitState({ sent: true })
      setLoading(false)
    } catch (e) {
      handleSubmitState({ error: true })
      setLoading(false)
    }
  }

  const closeButton = setIsModalOpen && (
    <S.CloseButton
      className='border-0 bg-transparent pt-3 pt-md-4'
      onClick={() => {
        setIsModalOpen(false)
        resetForm()
      }}
    >
      <Close width='24' height='24' color='orange--extra' />
    </S.CloseButton>
  )

  const form = !submitState.error && !submitState.sent && (
    <S.FormDiv>
      <form
        onSubmit={handleSubmit(onSubmitForm)}
        className='form--container'
      >
        <S.Title>
          Cadastre-se e tenha acesso a plataforma para organizar os seus clientes.
        </S.Title>
        <div className='form--body'>
          <Input
            className='input'
            id='name'
            name='name'
            label='Nome'
            placeholder='Digite seu nome'
            error={errors.name && true}
            infoText={errors.name?.message || (errors.name?.type && 'Insira um nome válido')}
            ref={register({
              required: 'Insira um nome válido' || true,
              validate: {
                biggerThan3: (value: string) => value.length > 3 ? true : 'Insira pelo menos 3 caracteres',
                haveNumbers: (inputValue: string) => inputValue.match(/\d/g) ? false : true || 'Digite um nome válido',
              },
            })}
          />

          <Input
            className='input'
            id='cellphone'
            name='cellphone'
            label='Celular'
            placeholder='Digite seu celular'
            error={errors.cellphone && true}
            infoText={errors.cellphone?.message || (errors.cellphone?.type && 'Insira um telefone válido')}
            ref={register({
              required: 'Insira um telefone' || true,
              validate: {
                isValid: (value: string) => value.match(/^\(\d{2}\) \d{4,5}-\d{4}$/) ? true : 'Digite um número válido',
              },
              pattern: {
                value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                message: 'Insira um número de telefone válido',
              },
            })}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const maskValue = Masks.MaskPHONE(event.target.value)
              if (errors.cellphone) {
                setValue('cellphone', maskValue, { shouldValidate: true })
                return
              }
              setValue('cellphone', maskValue)
            }}
          />
          <Input
            className='input'
            id='email'
            name='email'
            label='E-mail'
            placeholder='Digite seu e-mail'
            error={errors.email && true}
            infoText={errors.email?.message || (errors.email?.type && 'Insira um endereço de e-mail válido')}
            ref={register({
              required: 'Insira um email' || true,
              validate: {
                isEmail: (value: string) => Validations.isEmail(value) || 'Digite um e-mail válido',
              },
            })}
          />
          <Input
            className='input'
            id='CpfCnpj'
            name='CpfCnpj'
            label='CPF/CNPJ (Opcional)'
            placeholder='Digite seu CPF/CNPJ'
            error={errors.CpfCnpj && true}
            infoText={errors.CpfCnpj?.message}
            ref={register({
              pattern: {
                value: /^(\d{3}\.?\d{3}\.?\d{3}-?\d{2}|\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/,
                message: 'Insira um CPF ou CNPJ válido',
              },
            })}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const MaskValue = Masks.MaskCPFCNPJ(event.target.value)
              setValue('CpfCnpj', MaskValue)
            }
            }
          />
          <Input
            className='input'
            id='consultorName'
            name='consultorName'
            label='Nome da Consultoria (Opcional)'
            placeholder='Digite o nome'
            ref={register}
          />
          <AcceptTerms
            styles='acceptTerms'
            label='Estou de acordo com a '
            accept={accept}
            setAccept={(value: boolean) => setAccept(value)}
            name='acceptTerm'
            dataLayer={{
              section: 'modal',
              section_name: 'Cadastre-se e tenha acesso a plataforma para organizar os seus clientes.',
              element_name: 'AcceptTerm',
              element_action: 'click button',
            }}
          />

          <Button
            className='mt-4'
            as='button'
            type='submit'
            disabled={!accept}
            isLoading={loading}
            onClick={() => {
              sendDataLayerEvent({
                section: 'modal',
                element_action: 'click button',
                section_name: 'Cadastre-se e tenha acesso a plataforma para organizar os seus clientes.',
                element_name: 'Estou Interessado(a)',
              })
            }}
          >
            Estou Interessado(a)
          </Button>
        </div>
      </form>
    </S.FormDiv>
  )

  const success = submitState.sent && (
    <S.SuccessDiv>
      <OrangeDSIcon
        className='icon'
        icon='check-circle'
        size='XL'
        color='green'
      />
      <h5 className='title'>
        Pronto! Recebemos os seus dados.
      </h5>
      <p className='text'>
        E em breve retornaremos por email.
      </p>
    </S.SuccessDiv>
  )

  const error = submitState.error && (
    <S.ErrorDiv>
      <OrangeDSIcon
        className='icon'
        icon='canceled'
        size='XL'
        color='red'
      />
      <h5 className='title'>
        Houve um erro por aqui.
      </h5>
      <p className='text'>
        Não foi possível criar o seu cadastro. Experimente voltar dentro de alguns instantes.
      </p>
    </S.ErrorDiv>
  )

  return (
    <>
      {
        domReady && (
          <Modal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            locationToRender={document.body}
          >
            <S.Container className='container'>
              {closeButton}
              <div className='row h-100'>
                <div className='col-12'>
                  {form}
                  {success}
                  {error}
                </div>
              </div>
            </S.Container>
          </Modal>
        )
      }
    </>
  )
}

export default ModalComponent
