import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const DivPrincipal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.tablet}{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .linkItem{
    font-family: Inter;
    font-weight: 600;
    font-size: 12px;
    line-height: 14.52px;
    color: ${grayscale[400]};
    text-decoration: none;
    transition: all 0.25s;
    margin: 0;

    &:hover{
      color: ${grayscale[600]};
    }

    &:focus{
      border: none !important;
    }
  }
  .noLinkItem{
    font-family: Inter;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: ${grayscale[500]};
    margin: 0;
  }

  .arrow-icon{
    margin: auto 16px ;
  }
`
