import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  background-color: ${ orange.extra };
  padding: 40px 0;

  @media (min-width: 1024px){
    padding: 64px 0;
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  .button{
    width: 100%;

    @media ${device.tablet}{
      width: 50%;
    }

    @media (min-width: 1024px){
      width: 40%;
    }
  }

  h2{
    font-family: Citrina, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
    color: white;
    margin-bottom: 16px;
    letter-spacing: 0;

    @media ${device.tablet}{
      text-align: center;
    }

    @media (min-width: 1024px){
      font-size: 40px;
      line-height: 44px;
    }
    
    @media ${device.desktopXXL}{
      width: 60%;
      margin: 0 auto 24px auto;
    }
  }

  p{
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;

    @media ${device.tablet}{
      text-align: center;
    }

    @media (min-width: 1024px){
      font-size: 16px;
      line-height: 19.36px;
    }

    @media ${device.desktopXXL}{
      width: 60%;
      margin: 0 auto 24px auto;
    }
  }
  
  .text{
    color: white; 
  }
`

export const Card = styled.article`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin: 24px 0;
  display: flex;
  flex-direction: column;

  @media ${device.tablet}{
    width: 216px;
    min-height: fit-content;
    height: auto;
    margin: 12px 0;
  }

  @media (min-width: 1024px){
    margin: 12px 0;
    width: 32%;
    padding: 30px 26px;
  }

  .cardTitleDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    
    @media ${device.tablet}{
      flex-direction: column;
      align-items: flex-start;
    }

    .iconDiv{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 16px;

      @media ${device.tablet}{
        align-items: flex-start;
        margin: 0 0 16px 0;
        width: 32px;
        height: 32px;
      }

      @media ${device.desktopXXL}{
        margin: 0 0 24px 0;
      }

      .icon{
        width: 24px;
        height: 24px;
        
        @media ${device.tablet}{
          width: 32px;
          height: 32px;
        }
      }
    }

    .cardTitle{
      font-family: Citrina, sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: ${grayscale[500]};
      margin: 0;

      @media (min-width: 1024px){
        font-size: 24px;
        line-height: 28.8px;
      }
    }
  }

    .cardText{
      color: ${grayscale[500]};
      margin: 0;

      @media ${device.tablet}{
        margin: 0;
        text-align: left;
      }

      @media (min-width: 1024px){
        font-size: 16px;
        line-height: 19.36px;
        width: 100%;
      }
    }
`

export const CardContainer = styled.div`
  @media ${device.tablet}{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 28px 0;
  }

  @media (min-width: 1024px){
    margin: 28px 0;
  }

  @media ${device.desktopXXL}{
  }
`
