import React from 'react'
import * as S from './styles'
import CardsInfo from './CardsInfo.json'
import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { SectionsProps } from '../../types'

type CardsInfoProps = {
  title: string;
  span: string;
  text: string;
}

const FacaParteDessaJornada = ({ setIsModalOpen }: SectionsProps) => {
  const [ sendDataLayerEvent ] = useDataLayer()

  return (
    <S.ContentHeader>
      <div className='container'>
        <div className='col-12'>
          <S.DivPrincipal>
            <h2 className='title'>
              Faça parte dessa jornada de sucesso
            </h2>
            <S.CardsDiv>
              {CardsInfo.map((item: CardsInfoProps) => {
                const index = CardsInfo.indexOf(item)
                return (
                  <S.Card
                    key={`cardN${index}`}
                  >
                    <p className='cardTitle'>
                      {item.title}
                      <span className='spanCardTitle'>
                        {item.span}
                      </span>
                    </p>
                    <p className='text'>
                      {item.text}
                    </p>
                  </S.Card>
                )
              })
              }
            </S.CardsDiv>

            <Button
              className='button'
              onClick={() => {
                setIsModalOpen(true)
                sendDataLayerEvent({
                  section: 'dobra_05',
                  section_name: 'Faça parte dessa jornada de sucesso',
                  element_name: 'Saber Mais!',
                  element_action: 'click button',
                })
              }}
            >
              Saber Mais!
            </Button>
          </S.DivPrincipal>
        </div>
      </div>
    </S.ContentHeader>
  )
}

export default FacaParteDessaJornada
