import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  padding: 40px 0;

  @media (min-width: 1024px){
    padding: 64px 0;
  }
`

export const DivPrincipal = styled.div`
  
  .title{
    font-family: Citrina, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    color: ${grayscale[500]};

    @media ${device.tablet} {
      margin: 0;
    }

    @media (min-width: 1024px){
      font-size: 40px;
      line-height: 44px;
    }

    @media ${device.desktopXXL}{
      font-size: 48px;
      line-height: 52.8px;
    }

  }

  .button{
    width: 100%;
    
      @media ${device.tablet}{
        width: 60%;
        display: flex;
        margin: 0 auto;
      }

      @media ${device.desktopXXL} {
        width: 40%;
      }
    }
`

export const OrangeLine = styled.div`
  border: 1px solid ${orange.extra};

  position: absolute;
  left: 10%;
  top: 23%;
  height: 57%;
  
  @media ${device.tablet}{
    height: 0;
    width: 73%;
    left: 14%;
    top: 28%;
  }
  
  @media (min-width: 1024px){
    top: 31%;
  }

  @media ${device.desktopXXL}{
    width: 72%;
    top: 32%;
  }
`

export const Cards = styled.article`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media ${device.tablet}{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .cardText{
    background-color: ${grayscale[100]};
    padding: 0 32px;
    width: 270px;
    height: 175px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 21.78px;
    color: ${grayscale[500]};

    @media ${device.tablet}{
      font-family: Citrina, sans-serif;
      font-weight: 500;
      margin: 28px 0 0 0;
      width: 216px;
      padding: 20px;
      
      font-size: 20px;
      line-height: 24px;
      text-align: center;
    }

    @media (min-width: 1024px){
      width: 296px;
    }

    @media ${device.desktopXXL}{
      width: 357px;

      line-height: 28.8px;
      font-size: 24px;
    }
  }

  .cardText1{
    border-radius: 12px 12px 12px 0 ;
    @media ${device.tablet}{
      border-radius: 12px 0 12px 12px ;
    }
  }

  .cardText2{
    border-radius: 0 12px 12px 0;
    margin: 36px 0;
    @media ${device.tablet}{
      margin: 28px 0 0 0;
      border-radius: 0 0 12px 12px ;
    }
  }

  .cardText3{
    border-radius: 0 12px 12px 12px;
    @media ${device.tablet}{
      
    }
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 0;
    margin: 0;
  }

  @media (min-width: 1024px){
    padding: 64px 0;
  }
`

export const Number = styled.p`
  display: flex;
  text-align: center;
  justify-content: center;
  background: ${orange.extra};
  border-radius: 12px;
  padding: 12px 20px;
  position: absolute;
  width: fit-content;
  margin: 0;

  color: white;
  font-family: Sora, sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 28.8px;
  left: 1px;

  @media ${device.tablet} {
    left: auto;
  }
`
