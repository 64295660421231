import React from 'react'
import * as S from './styles'
import pageContext from '../../PageContext.json'
import FacComponent from './components/Faq/_index'

const FAQ = () => {
  return (
    <S.ContentHeader>
      <div className='container'>
        <div className='col-12 p-0'>
          <S.DivPrincipal>
            <h2 className='title'>Perguntas frequentes</h2>
            <p className='text'>Tudo o que você precisa saber para usufruir melhor da nossa plataforma.</p>
            <FacComponent answerData={pageContext.structuredData.faq} />
          </S.DivPrincipal>
        </div>
      </div>
    </S.ContentHeader>
  )
}

export default FAQ
