import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'
import styled, { keyframes } from 'styled-components'

const animatedModal = keyframes`
  from{
    right: -100%;
  } to {
    right: 0;
  }
`

export const Container = styled.div`
  background-color: white;
  min-height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  
  @media ${device.tablet}{
    padding: 0 80px;
    max-width: 548px;
  }

  @media ${device.desktopLG}{
    max-width: 640px; 
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 25px;
  top: 0;
  z-index: 10;
  outline: none !important;

  &:focus{
    outline: none;
    border: none;
  }
`

export const Title = styled.h3`
  font-family: Citrina, sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 44px;
  color: ${grayscale[500]};
  margin-bottom: 12px;
`

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .form--container{
    padding: 32px 0;
    height: 100%;

    @media ${device.desktopLG}{
      padding: 64px 0;
    }
    .form--body{
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;

      .input{
        margin: 12px;
      }

      .acceptTerms{
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 19.94px;
        color: ${grayscale[500]};
      }

      .form-input-check{
        &:checked ~ label{
          &::before {
            background: ${orange.extra} !important;
            border: none !important;
          }
        }
      }
    }
  }
`

export const SuccessDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .title{
    font-family: Citrina, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    width: 50%;
    color: ${grayscale[500]};

    margin: 40px 0 24px 0;
  }

  .text{
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    text-align: center;
    color: ${grayscale[500]};
  }
`

export const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .title{
    font-family: Citrina, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    width: 70%;
    color: ${grayscale[500]};

    margin: 40px 0 24px 0;
  }

  .text{
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    text-align: center;
    width: 70%;
    color: ${grayscale[500]};
  }
`
