import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  padding: 40px 0;

  @media ${device.tablet}{
    padding: 40px 0 58px 0;
  }

  @media (min-width: 1024px){
    padding: 64px 0;
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  .button{
    width: 100%;

    @media ${device.tablet} {
      width: 60%;
      display: flex;
      margin: 0 auto;
    }

    @media (min-width: 1024px){
      width: 50%;
    }
    
    @media ${device.desktopXXL}{
      width: 40%;
    }
  }
`

export const DivPrincipal = styled.div`
  .title{
    font-family: Citrina, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
    text-align: center;
    color: ${grayscale[500]};

    @media (min-width: 1024px) {
      font-size: 40px;
      line-height: 44px;
      margin-bottom: 24px;
    }

    @media ${device.desktopXXL}{
      font-size: 48px;
      line-height: 52.8px;
    }
  }
  .text{
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    text-align: center;
    color: ${grayscale[400]};
    
    @media (min-width: 1024px) {
      font-size: 18px;
      line-height: 21.78px;
      margin-bottom: 24px;
    }
  }
`

export const VideoDiv = styled.div`
  margin: 24px 0;
  background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/cons-video-360/image.webp);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 168px;
  background-position: center;

  @media ${device.tablet}{
    margin: 32px 0;
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/cons-video-web/image.webp);
    height: 384px;
  }

  @media (min-width: 1024px){
    margin: 40px 0;
  }

  @media ${device.desktopXXL}{
    height: 418px;
  }

  .openVideo{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .playVideoIcon{
    width: 60px;

    @media ${device.tablet}{
      width: 90px;
    }
  }
`
