import React from 'react'
import * as S from './styles'
import { Button } from '@interco/inter-ui/components/Button'
import CardsInfo from './CardsInfo.json'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { SectionsProps } from '../../types'

type CardsInfoProps = {
  index: string;
  text: string;
}

const VejaComoESimples = ({ setIsModalOpen }: SectionsProps) => {
  const [ sendDataLayerEvent ] = useDataLayer()

  return (
    <S.ContentHeader>
      <div className='container'>
        <div className='col-12 p-md-0'>
          <S.DivPrincipal>
            <S.OrangeLine />
            <h2 className='title'>Veja como é simples</h2>
            <S.CardContainer>
              {CardsInfo.map((item: CardsInfoProps) => {
                return (
                  <S.Cards
                    key={`card${item.index}`}
                    className={`card${item.index}`}
                  >
                    <S.Number className={`number${item.index}`}>{item.index}</S.Number>
                    <p className={`cardText${item.index} cardText`}>{item.text}</p>
                  </S.Cards>
                )
              })}
            </S.CardContainer>
            <Button
              className='button'
              onClick={() => {
                setIsModalOpen(true)
                sendDataLayerEvent({
                  section: 'dobra_06',
                  section_name: 'Veja como é simples',
                  element_name: 'Quero Cadastrar',
                  element_action: 'click button',
                })
              }}
            >
              Quero Cadastrar
            </Button>
          </S.DivPrincipal>
        </div>
      </div>
    </S.ContentHeader>
  )
}

export default VejaComoESimples
