import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`

@media (min-width: 1024px){
  background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/lp-consultor-investimentos-1024/image.png);
  background-position-x: right;
  background-size: contain;
  background-repeat: no-repeat;
  }

  @media ${device.desktopXXL}{
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/cons-hero-1440-1024/image.webp);
  }
`

export const DivPrincipal = styled.div`
  padding: 40px 0 24px 0;
  
  @media ${device.tablet}{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 64px 0 40px 0;
  }

  @media (min-width: 1024px) {
    align-items: flex-start;
    padding: 115px 0;
  }

  @media ${device.desktopXXL}{
    padding: 195.5px 0;
  }

  h1{
    font-family: Citrina, sans-serif;
    font-weight: 700;
    font-size: 40px;
    line-height: 44px;
    color: ${grayscale[500]};
    margin: 16px 0;

    @media ${device.tablet}{
      text-align: center;
      width: 90%;
    }

    @media (min-width: 1024px){
      text-align: left;
      font-size: 48px;
      line-height: 52.8px;
      width: 100%;
    }
  }

  p{
    font-family: Inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 21.78px;
    color: ${ grayscale[400] };
    margin-bottom: 32px;

    @media ${device.tablet}{
      text-align: center;
      font-size: 16px;
      line-height: 19.36px;
    }

    @media (min-width: 1024px){
      text-align: left;
      font-size: 18px;
      line-height: 21.78px;
    }
  }

  .button{
    width: 100%;

    @media ${device.tablet}{
      width: 60%;
    }

    @media ${device.desktopLG}{
      width: 100%;
    }
  }
`

export const Image = styled.img`
  width: 100%;
`
