import React from 'react'
import * as S from './styles'
import OpenVideo from '../../../../../../components/OpenVideo/index'
import PlayVideoOrange from 'src/assets/images/shared/play-video-orange.png'
import { Button } from '@interco/inter-ui/components/Button'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { SectionsProps } from '../../types'

const ConhecaGestaoDeCarteiras = ({ setIsModalOpen }: SectionsProps) => {
  const [ sendDataLayerEvent ] = useDataLayer()

  return (
    <S.ContentHeader>
      <div className='container'>
        <div className='col-12 p-0'>
          <S.DivPrincipal>
            <h2 className='title'>
              Conheça a gestão de carteiras de investimentos
            </h2>
            <p className='text'>
              Acompanhe constantemente as carteiras de cada cliente, atendendo suas necessidades de forma personalizada.
            </p>
          </S.DivPrincipal>
          <S.VideoDiv>
            <OpenVideo
              link='https://www.youtube.com/embed/m7NXMGmvfLE'
              styles='openVideo'
            >
              <img
                className='playVideoIcon'
                src={PlayVideoOrange}
                alt='Assistir ao vídeo'
              />
            </OpenVideo>
          </S.VideoDiv>
          <Button
            className='button'
            onClick={() => {
              setIsModalOpen(true)
              sendDataLayerEvent({
                section: 'dobra_04',
                section_name: 'Conheça a gestão de carteiras de investimentos',
                element_name: 'Quero Cadastrar',
                element_action: 'click button',
              })
            }}
          >
            Quero Cadastrar
          </Button>
        </div>
      </div>
    </S.ContentHeader>
  )
}

export default ConhecaGestaoDeCarteiras
