import { orange } from 'src/styles/colors'
import styled from 'styled-components'

type ButtonProps = {
  $width?: string;
}

const DefaultDiv = styled.div<{$width?: string}>`
  cursor: pointer;
  border-radius: 8px;
  padding: 15px 16px;
  width: ${(props: ButtonProps) => props ? props.$width : '100%' };
  transition: color 0.25s ease-in-out;
  transition: background-color 0.25s ease-in-out;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;

  &:focus{
    border: none !important;
  }
`

export const Orange = styled(DefaultDiv)`
  color: white;
  background-color: ${orange.extra};
  
  &:hover{
    background-color: ${orange[300]};
  }
  `

export const White = styled(DefaultDiv)`
  background-color: white;
  color: ${ orange.extra };
  
  &:hover{
    background-color: ${orange[200]}
  }
  `

const DefaultLink = styled.a<{$width?: string}>`
  cursor: pointer;
  border-radius: 8px;
  padding: 15px 16px;
  width: ${(props: ButtonProps) => props ? props.$width : '100%' };
  transition: color 0.25s ease-in-out;
  text-decoration: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;

  &:focus{
    border: none !important;
  }
`

export const OrangeAsLink = styled(DefaultLink)`
  color: white;
  background-color: ${orange.extra};
  
  &:hover{
    color: ${orange.dark};
  }
`

export const WhiteAsLink = styled(DefaultLink)`
  background-color: white;
  color: ${ orange.extra };

  &:hover{
    color: ${orange.extra}
  }
`
