import React, { useState } from 'react'
import * as S from './styles'

import PageContext from './PageContext.json'

import HeroSejaUmConsultor from './sections/hero-seja-um-consultor-de-investimentos/_index'
import BeneficiosParaConsultores from './sections/beneficios-para-consultores/_index'
import MaiorPortifolioDeProdutos from './sections/maior-portifolio-de-produtos/_index'
import ConhecaGestaoDeCarteiras from './sections/conheca-a-gestao-de-carteiras/_index'
import FacaParteDessaJornada from './sections/faca-parte-dessa-jornada/_index'
import VejaComoESimples from './sections/veja-como-e-simples/_index'
import ModalComponent from './components/modal/_index'
import BaseLayout from 'src/layouts/BaseLayout'
import FAQ from './sections/FAQ/_index'

const PortalConsultor = () => {
  const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false)
  
  return (
    <S.Wrapper>
      <BaseLayout
        pageContext={PageContext}
      >
        <ModalComponent isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <HeroSejaUmConsultor setIsModalOpen={setIsModalOpen} />
        <BeneficiosParaConsultores setIsModalOpen={setIsModalOpen} />
        <MaiorPortifolioDeProdutos setIsModalOpen={setIsModalOpen} />
        <ConhecaGestaoDeCarteiras setIsModalOpen={setIsModalOpen} />
        <FacaParteDessaJornada setIsModalOpen={setIsModalOpen} />
        <VejaComoESimples setIsModalOpen={setIsModalOpen} />
        <FAQ />
      </BaseLayout>
    </S.Wrapper>
  )
}

export default PortalConsultor
