import React, { useEffect } from 'react'
import * as S from './styles'
import BreadCrumbs from './components/BreadCrumb/_index'
import { Button } from '@interco/inter-ui/components/Button'
import { Theme, initTheme } from '@interco/inter-ui'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { SectionsProps } from '../../types'

const HeroSejaUmConsultor = ({ setIsModalOpen }: SectionsProps) => {
  const width = useWidth()
  function switchImage (widthToCompare: number) {
    switch (true) {
      case (widthToCompare >= 768):
        return 'https://central-imagens.bancointer.com.br/images-without-small-versions/cons-hero-768/image.webp'
      case (widthToCompare <= 360):
        return 'https://central-imagens.bancointer.com.br/images-without-small-versions/cons-hero-360/image.webp'
      default:
        return 'https://central-imagens.bancointer.com.br/images-without-small-versions/cons-hero-360/image.webp'
    }
  }

  useEffect(() => {
    initTheme(Theme.PF)
  }, [])

  const [ sendDataLayerEvent ] = useDataLayer()

  return (
    <S.ContentHeader>
      {width < 1024
        ? <S.Image src={switchImage(width)} alt='Consultor de investimentos com o smartphone na mão e de camisa laranja e azul' />
        : ''
      }
      <div className='container'>
        <div className='col-12 col-lg-5 p-0'>
          <S.DivPrincipal aria-label={width >= 1024 ? 'Consultor de investimentos com o smartphone na mão e de camisa laranja e azul' : ''}>
            <BreadCrumbs />
            <h1>Seja um consultor de investimentos bem sucedido</h1>
            <p>Faça a gestão de patrimônio dos seus clientes com uma plataforma completa e segura, sendo um consultor CVM com o Inter.</p>
            <Button
              className='button'
              onClick={() => {
                setIsModalOpen(true)
                sendDataLayerEvent({
                  section: 'dobra_01',
                  section_name: 'Seja um consultor de investimentos bem sucedido',
                  element_name: 'Estou Interessado(a)',
                  element_action: 'click button',
                })
              }}
            >
              Estou Interessado(a)
            </Button>
          </S.DivPrincipal>
        </div>
      </div>
    </S.ContentHeader>
  )
}

export default HeroSejaUmConsultor
