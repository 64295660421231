import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import * as S from './styles'
import { grayscale } from 'src/styles/colors'

const BreadCrumbs = () => {
  return (
    <S.DivPrincipal>
      <div>
        <OrangeIcon
          className='icon'
          icon='home'
          size='MD'
          color={grayscale[400]}
        />
      </div>

      <div>
        <OrangeIcon
          className='arrow-icon'
          icon='arrow-right'
          size='SM'
          color='black'
        />
      </div>

      <a
        href='/pra-voce/investimentos'
        className='linkItem'
      >
        Investimentos
      </a>

      <div>
        <OrangeIcon
          className='arrow-icon'
          icon='arrow-right'
          size='SM'
          color='black'
        />
      </div>

      <p
        className='noLinkItem'
      >
        Consultor de Investimentos
      </p>

    </S.DivPrincipal>
  )
}

export default BreadCrumbs
