import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const ContentHeader = styled.section`
  padding: 40px 0;

  @media ${device.tablet} {
    padding: 64px 0;
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  h3{
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
    color: ${grayscale[500]};
  }
`
export const DivPrincipal = styled.div`
  .title{
    font-family: Citrina, sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 33.6px;
    color: ${grayscale[500]};
    margin-bottom: 24px;
    
    @media ${device.desktopXXL}{
      font-size: 40px;
      line-height: 44px;
    }
  
  }

  .text{
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    color: ${grayscale[500]};
    margin-bottom: 24px;

    @media ${device.desktopXXL}{
      font-size: 18px;
      line-height: 21.78px;
    }
  }
`
